import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  termsData: null,
};

export const getTermsData = createAsyncThunk(
  "termspage",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/termsandconditions`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const termsSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {},
  extraReducers: {
    [getTermsData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getTermsData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.termsData = action.payload?.data;
    },
    [getTermsData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.termsData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
  },
});

export default termsSlice.reducer;
