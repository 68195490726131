import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  dailyScheduleData: null,
};

export const getDailyScheduleData = createAsyncThunk(
  "dailySchedule/getDailyScheduleData",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/schedules/daily`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const dailyScheduleSlice = createSlice({
  name: "dailySchedule",
  initialState,
  reducers: {

  },
  extraReducers: {
    [getDailyScheduleData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getDailyScheduleData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.dailyScheduleData = action.payload?.data;
    },
    [getDailyScheduleData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.dailyScheduleData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

  },
});

export default dailyScheduleSlice.reducer;
