import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  featuredTestimonialData: null,
};

export const getFeaturedTestimonialData = createAsyncThunk(
  "featuretestimonials/getFeaturedTestimonialData ",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/featuredtestimonials`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const featureTestimonialsSlice = createSlice({
  name: "featuretestimonials",
  initialState,
  reducers: {

  },
  extraReducers: {
    [getFeaturedTestimonialData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getFeaturedTestimonialData.fulfilled]: (state, action) => {
      //state.status = "succeeded";
      state.featuredTestimonialData = action.payload?.data;
    },
    [getFeaturedTestimonialData.rejected]: (state, action) => {
      //state.status = "failed";
      if (action.payload?.data.length !== 0) {
        state.featuredTestimonialData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

  },
});

export default featureTestimonialsSlice.reducer;
