import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  programCategoryData: null,
};

export const getProgramCategory = createAsyncThunk(
  "programCategroy/getProgramCategory",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`v1/programs/page`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const programCategorySlice = createSlice({
  name: "programCategroy",
  initialState,
  reducers: {

  },
  extraReducers: {
    [getProgramCategory.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getProgramCategory.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.programCategoryData = action.payload?.data;
    },
    [getProgramCategory.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.programCategoryData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

  },
});

export default programCategorySlice.reducer;
