import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAxiosInstance } from '../../apis/index'

const initialState = {
  status: 'idle',
  error: null,
  message: null,
  footerData: null,
  expires_at: "",
}

export const getFooterData = createAsyncThunk(
  'footer/getFooterData',
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance()
    try {
      const response = await api.get(`/v1/footermenus`)
      return response
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

const footerSlice = createSlice({
  name: 'footer',
  initialState,
  reducers: {},
  extraReducers: {
    [getFooterData.pending]: (state, action) => {
      state.status = 'loading'
      state.message = null
    },
    [getFooterData.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      state.footerData = action.payload?.data
      state.expires_at = new Date().getTime()
    },
    [getFooterData.rejected]: (state, action) => {
      state.status = 'failed'
      state.expires_at = null;
      if (action.payload?.data.length !== 0) {
        state.footerData = action.payload?.data
      }
      state.message = action.payload?.message
    },
  },
})

export default footerSlice.reducer
