import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  homeBannerData: {
    banner: {
      type: '',
      title: '',
      image: [],
    },
    bannerSchedule: {},
  },
  expires_at: "",
  scheduleData: null,
};

export const getHomeBannerData = createAsyncThunk(
  "homeBanner/getHomeBannerData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/homebanner`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getScheduleData = createAsyncThunk(
  "homeBanner/getScheduleData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/homebannerschedule`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const homeBannerSlice = createSlice({
  name: "homeBanner",
  initialState,
  reducers: {},
  extraReducers: {
    [getHomeBannerData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getHomeBannerData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.homeBannerData = action.payload?.data;
      state.expires_at = new Date().getTime();
    },
    [getHomeBannerData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.homeBannerData = action.payload?.data;
      }
      state.message = action.payload?.message;
      state.expires_at = null;
    },

    //
    [getScheduleData.pending]: (state, action) => {
      state.status = "loading";
      state.scheduleData = null;
    },
    [getScheduleData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.scheduleData = action.payload?.data;
    },
    [getScheduleData.rejected]: (state, action) => {
      state.status = "failed";
      state.scheduleData = action.payload?.message;
    },
  },
});

export default homeBannerSlice.reducer;
