import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  searchPrayerData: [],
  page: 1,
};

export const getSearchPrayerData = createAsyncThunk(
  "prayer/getSearchPrayerData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`v1/prayer/search`, { params });
      return { response: response, params: params };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const searchPrayerSlice = createSlice({
  name: "searchprayer",
  initialState,
  reducers: {},
  extraReducers: {
    [getSearchPrayerData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getSearchPrayerData.fulfilled]: (state, action) => {
      state.status = "succeeded";

      if (action.payload?.response?.data.total) {
        //Pagination is enabled
        //If page is greater than 1
        if (action.payload.params.page > 1) {
          //Concact the new data
          let newData = state.searchPrayerData.searchResult.concat(
            action.payload.response?.data.searchResult
          );
          state.searchPrayerData.searchResult = newData;
        } else {
          state.searchPrayerData = action.payload?.response?.data;
          // state.page = state.page + 1;
        }
      } else {
        state.searchPrayerData = action.payload?.response?.data;
      }
    },
    [getSearchPrayerData.rejected]: (state, action) => {
      state.status = "failed";
      if (action.payload?.response.data.length !== 0) {
        state.searchPrayerData = action.payload?.response?.data;
      }
      state.message = action.payload?.response.message;
    },
  },
});

export default searchPrayerSlice.reducer;
