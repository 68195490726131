import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  dailyMassData: null,
};

export const getDailyMassData = createAsyncThunk(
  "dailyMass/getDailyMassData",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/dailymass`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postDailyMassData = createAsyncThunk(
  "dailyMass/postDailyMassData",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/dailymassnewslettersubscription`,params);
      
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const dailyMassSlice = createSlice({
  name: "dailyMass",
  initialState,
  reducers: {
    
  },
  extraReducers: {
    [getDailyMassData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getDailyMassData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.dailyMassData = action.payload?.data;
    },
    [getDailyMassData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.dailyMassData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
    
  },
});

export default dailyMassSlice.reducer;
