import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  articlesData: null,
  expires_at: '',
};

export const getArticleData = createAsyncThunk(
  "article/getArticleData",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/bookssubscription`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {

  },
  extraReducers: {
    [getArticleData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getArticleData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.articlesData = action.payload?.data;
      state.expires_at = new Date().getTime();
    },
    [getArticleData.rejected]: (state, action) => {
      state.status = "failed";
      state.expires_at = null;
      if (action.payload?.data.length !== 0) {
        state.articlesData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },

  },
});

export default articleSlice.reducer;
