import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAxiosInstance } from '../../apis/index'

const initialState = {
  status: 'idle',
  error: null,
  message: null,
  programListData: {
    pages: null,
    status: null,
    programs: [],
    total: null,
  },
}

export const getProgramList = createAsyncThunk(
  'programs/getProgramList',
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance()
    try {
      let url = `v1/programs`;
      if (params.category === 'retreat') {
        url = `v1/retreat`;
      }

      return await api.get(
        `${url}`,
        { params: params },
      )
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const getProgramListMore = createAsyncThunk(
  'programs/getProgramList',
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance()
    try {
      let url = `v1/programs`;
      if (params.category === 'retreat') {
        url = `v1/retreat`;
      }

      let response = await api.get(
        `${url}`,
        { params: params },
      )
      return response;


    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

const programListSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {},
  extraReducers: {
    [getProgramList.pending]: (state, action) => {
      state.status = 'loading'
      state.message = null
      if (action?.meta?.arg?.page && action.meta.arg.page === 1) {

        state.programListData = {
          pages: 1,
          programs: [],
        }
      }
    },
    [getProgramList.fulfilled]: (state, action) => {
      state.status = 'succeeded'

      if (state.programListData.pages && state.programListData.pages > 1) {
        let newData = state.programListData.programs.concat(
          action.payload?.data?.programs
        );
        state.programListData.programs = newData;
      } else {
        state.programListData = action.payload?.data
      }
    },
    [getProgramList.rejected]: (state, action) => {
      state.status = 'failed'
      if (action.payload?.data.length !== 0) {
        state.programListData = action.payload?.data
      }
      state.message = action.payload?.message
    },
  },
})

export default programListSlice.reducer
