import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  prayerCampaignData:
  {
    clickcount: "",
    prayer: "",
  },
  saintData: null,
  wordSaintData: null,
};

export const getCampaignData = createAsyncThunk(
  "campaign/getCampaignData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      return await api.get(`/v1/prayercampaignsaintword?time=` + new Date().getTime());
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const postCampaignData = createAsyncThunk(
  "campaign/postCampaignData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      return await api.post(`/v1/prayerclick`, params);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {},
  extraReducers: {
    //GET EVENTS
    [getCampaignData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getCampaignData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.prayerCampaignData = action.payload?.data?.prayercampaign;
      state.saintData = action.payload?.data?.saintoftheday;
      state.wordSaintData = action.payload?.data?.wordoftheday;
    },
    [getCampaignData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.campaignData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
    //POST EVENTS
    [postCampaignData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [postCampaignData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.prayerCampaignData =
      {
        ...state.prayerCampaignData,
        clickcount: action.payload?.data.clickcount,
      };

    },
    [postCampaignData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.campaignData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
  },
});

export default campaignSlice.reducer;
