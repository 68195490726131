import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  seoData: [],
};

export const getSeoData = createAsyncThunk(
  "seo/getSeoData",
  async (params,{ rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v3/seo`, {params : {slug:params.page,type: params.type}});
      return {data:response.data,page:params.page};
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
); 


const seoSlice = createSlice({
  name: "seo",
  initialState,
  reducers: {
    
  },
  extraReducers: {
    [getSeoData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getSeoData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.seoData = [...state.seoData,{page:action.payload?.page,seoDetails:action.payload?.data}];
    },
    [getSeoData.rejected]: (state, action) => {
      state.status = "failed";

      if (action.payload?.data.length !== 0) {
        state.seoData = action.payload?.data;
      }
      state.message = action.payload?.message;
    },
    
  },
});

export default seoSlice.reducer;
